@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,600;0,700;1,600;1,700&family=Montserrat:ital,wght@0,300;0,400;0,700;1,400;1,700&display=swap');

@keyframes glitchColor {

    0%   {background-color:#FF3AFF;}
    20%  {background-color:#FF5C00;}
    40%  {background-color:#00FFFF;}
    60%  {background-color:#FFFF00;}
    80%  {background-color:#00FF97; }
    100%  {background-color:#ff0022;}

}

@keyframes bothColor {

    0%   {background-color:#ff0022;}
    100%  {background-color:#ff0022;}

}

.container,
.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
     .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1200px;
    }
}

*, ::after, ::before {
    box-sizing: border-box;
}

body {
    background-color: white;
}

ul, li {
    list-style: none;
}

a {
    text-decoration: none;
    color: black;
}

article a, .ref-hidden a {
    position: relative;
}

.content-article article a, .content-article .ref-hidden a {
    white-space: nowrap;
}

article a::before {
    content: "";
    position: absolute;
    top: 63%;
    width: 100%;
    left: 0;
    height: 3px;
    border-radius: 4px;
    background: #00FFFF;
    z-index: -1;
    display: inline-block;
}

.ref-hidden a {
    padding: 5px;
    display: block;
    margin-bottom: 5px;
    text-decoration: underline;
    font-weight: bold;
}

.reference em {
    font-size: 0.8em;
    margin-top: 15px;
    display: block;
}

article.blue a::before {
    background: #00ffff;
}

article.pink a::before {
    background: #ff4eff;
}

article.green a::before {
    background: #00ff97;
}

a.link {
    padding: 0.5rem;
    border-radius: 25px;
}

i {
    position: relative;
}

i:before {
    content: "";
    position: absolute;
    top: 51%;
    width: 100%;
    left: 0;
    height: 9px;
    border-radius: 4px;
    background: #ffef21;
    z-index: -1;

}

h1 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 65px;
    text-align: center;
}


h2 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 65px;
    text-align: center;
}

.alert-success{
    font-size: 20px;
    display: block;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    animation-name: glitchColor;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    width: auto;
    padding: 0.5rem;
    margin: 0  10rem 3rem;
}


.services h2, .articles h2 {
    font-family: 'Montserrat', serif;
    text-transform: none;
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    margin-top: 40px;
}

.articles h2::before {
    content: "\2010";
    font-weight: bold;
    display: inline-block;
    width: 0.8em;
    font-size: 35px;
    font-family: Times, Times New Roman, Liberation Serif, FreeSerif, serif;
    text-align: left;
    color: #01ffff;
}


h3 {
    font-family: 'Montserrat', serif;
    font-weight: 700;
    font-size: 20px;
    text-transform: initial;
}

.for-2columns {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(50%, 1fr));
    font-family: 'Cormorant Garamond', serif;
    font-size: 24px;
}

.for-1col {
    text-align: left;
    font-family: 'Cormorant Garamond', serif;
    font-size: 22px;
}

.for-1col ul li::before {
    content: "\2022";
    font-weight: bold;
    display: inline-block;
    width: 0.8em;
    font-size: 20px;
    font-family: Times, Times New Roman, Liberation Serif, FreeSerif, serif;
    text-align: left;
    color: #ff0022;
}

main .content p.disc {
    text-align: left;
    margin-bottom: 2px;
    font-family: 'Montserrat', serif;
    font-weight: 700;
    font-size: 20px;
}

main .content p i {
    display: inline-block;
    position: relative;
}

main .content.cv h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    margin: 70px 0 30px 0 ;
}

main .content.cv h3 {
    margin: 0px 0px 7px 0 ;
    text-transform: initial;
}

main .content img {
    max-width: 100%;
}

main .content .second-contact li p {
    text-align: center;
    margin-bottom: 10px;
}

main .content.cv h2.dates {
    margin-top: 5px;
}
main .content.cv h2 span {
    position: relative;
    width: 25px;
    margin: 0 7px;
    display: inline-block;
    content: "";
    height: 6px;
    border-radius: 4px;
    z-index: -1;
    background: #00ffff;
    vertical-align: middle;
}



main .content p.disc::before {
    content: "—";
    font-weight: bold;
    display: inline-block;
    width: 0.8em;
    font-size: 20px;
    font-family: Times, Times New Roman, Liberation Serif, FreeSerif, serif;
    text-align: left;
    margin: auto 10 px auto auto;
}
main .content p.disc.blue::before {
    color: #00ffff;
}

main .content p.disc.pink::before {
    color: #ff4eff;
}

main .content p.disc.green::before {
    color: #00ff97;
}


a.logo {
    padding: 0;
}

a.logo:hover {
    padding: 0;
    animation: none;
}

.btn {
    padding: 20px;
    background: black;
    color:white;
    display: inline-block;
    font-style: italic;
    font-size: 20px;
    width: fit-content;
    border: none;
}

.btn-centered {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
}

header {
    min-height: 155px;
    border-bottom: 6px solid black;
    padding-top: 20px;
    position: relative;
}

footer {
    border-top: 6px solid black;
    padding: 50px 0;
    text-align: center;
    color: #555555;
    font-family: 'Montserrat', sans-serif;
    margin-top: 100px;
}

footer a {
    color: #555555;
}

.clear_b {
    clear: both !important;
    float: none !important;
    min-height: 0px !important;
    margin: 0px !important;
    border: none !important;
    padding: 0px !important;
    height: 0px;
    font-size: 0px;
}

main .content {
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;
}

main .content p {
    text-align: left;
    font-family: 'Cormorant Garamond', serif;
    margin-top: 10px;
    font-size: 22px;
}

.content.formation h2, h2.formation {
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    margin: 70px 0 10px 0;
    color: #ff0022;
}

main .content p img {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

main .content p img.left {
    float: left;
    margin-right: 15px;
    margin-left: initial;
    clear: both;
    margin-bottom: 20px;
}

main .content p img.right {
    float: right;
    margin-left: 15px;
    margin-right: initial;
    clear: both;
    margin-bottom: 20px;
}

main .content h3 {
    text-align: left;
    margin-bottom: 10px;
}

.logo {
    font-size: 50px;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 700;
}

.slogan {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    margin: 0;
}

.header-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    align-self: flex-end;
    margin-left: auto;
    margin-bottom: 20px;

    transition-duration: 0.5s;
    transition-timing-function: ease-in;
    transition-delay: 0.3s;

}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.navbar-nav {
    padding: 0;
    text-align: center
}

.navbar-nav ul {
    display:inline-block;
}

.navbar-nav li {
    text-align: left;
    margin-bottom: 10px;
}

.navbar-nav li a {
    white-space:nowrap;
    font-size: 1.3em;
    display: inline-block;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    padding: 5px 15px;
    margin: 0;
}

.navbar-nav li a.link:hover {
    animation-name: glitchColor;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    border-radius: 10px;
    padding: 5px 15px;
}

.navbar-nav li a.link.format:hover {
    background-color: #ff0022;
    border-radius: 10px;
    padding: 5px 15px;
    animation-name: bothColor;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    color: white;
}

.navbar-nav li a.link, .form-quote label a, a.sublink {
    position: relative;
    display: inline-block;
}


.button-download {
    padding: 5px;
    border: 1px solid black;

}

.navbar-nav li a.link:focus::before, .navbar-nav li  a.link.active::before, .form-quote label a::before {
    content: "";
    position: absolute;
    top: 61%;
    width: 94%;
    left: 3%;
    height: 9px;
    border-radius: 4px;
    z-index: -1;
    background: #00ffff;
}

a.sublink::before {
    content: "";
    position: absolute;
    top: 63%;
    width: 100%;
    left: 0;
    height: 3px;
    border-radius: 4px;
    background: #00FFFF;
    z-index: -1;
    display: inline-block;
}



.btn:hover {
    animation-name: glitchColor;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    border: none;
}

.hpapers {
    padding: 0 30px;
}

.hpapers h3::before {
    content: "\2010";
    font-weight: bold;
    display: inline-block;
    width: 0.8em;
    font-size: 35px;
    font-family: Times, Times New Roman, Liberation Serif, FreeSerif, serif;
    text-align: left;
}

.hpapers h3.blue::before {
    color: #00ffff;
}

.hpapers h3.pink::before {
    color: #ff4eff;
}

.hpapers h3.green::before {
    color: #00ff97;
}


.hpapers p {
    text-align: left;
}

.form-reference {
    text-align: left;
}

.form-reference label {
    font-family: 'Montserrat', serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 22px;
    text-align: left;
    width: 100%;
    display: block;
    margin-top: 25px;
    margin-bottom: 15px;
}

.form-reference  #reference_themes , .form-reference  #reference_natures {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(30%, 1fr));
}

.form-reference  #reference_themes label, .form-reference  #reference_natures label {
    font-family: 'Montserrat', serif;
    text-transform: lowercase;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    width: auto;
    display: inline-block;
    margin-top: 0;
}

#quote.form-quote {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(50%, 1fr));
}

.form-quote label, .form-login label {
    font-family: 'Cormorant Garamond', serif;
    font-weight: bold;
    font-size: 22px;
    text-align: left;
    width: auto;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 4px;
}

.form-login button {
    margin-top: 30px;
}

.form-quote label, .form-quote input, .form-login label {
    width: 100%;
    height: 25px;
}

.form-login input {
    width: 25%;
    height: 25px;
}

.form-quote div, .form-login div {
    margin: 0 35px 30px;
}

.form-quote .cont-textarea {
    grid-column: 1 / -1;
    text-align: left;
    width: 100%;
}

.form-quote textarea {
    width: 94%;
    min-height: 150px;
}

.homepage div.formation {
    background: url("../images/formation.jpg") repeat ;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    padding-bottom: 40px;
}

.formation .img_full {
    width: 100%;
}

.btn-pink {
    background: #ff0022;
    color: white;
}

.bottom-line {
    border-bottom: 2px solid black;
}

.references {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(30%, 1fr));
}

.reference {
    padding: 30px;
    position: relative;
}

main .content .reference img {
    width: 100%;
    max-width: 100%;
    margin: 0;
}
main .reference div.legend {
    position: relative;
}

main .reference:hover div.legend .ref-hidden-cont {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    background: rgba(0, 255, 255, 0.8);
    height: 100%;
    width: 100%;
}

main .reference div.legend .ref-hidden {
    display: none;
}

main .reference:hover div.legend .ref-hidden {
    position: absolute;
    bottom: 0;
    padding: 20px;
    display: block;
    color: black;
    font-weight: bold;
}

main .reference:hover div.legend .ref-hidden .arrow {
    background: url("../images/arrow.png") repeat ;
    width: 31px;
    height: 39px;
    position: absolute;
    bottom: -30px;
    left: 43%;
}




.form-reference .radio-form {
    margin-right: 15px;
}

.form-reference select {
    padding: 7px;
    min-width: 50%;
    font-size: 18px;
    font-family: 'Montserrat', serif;
}

.cont-centered {
    text-align: center;
    margin-top: 50px;
}

.form-quote .cont-centered {
    display: inline-grid;
    grid-column: 1 / -1;
}

main .content p.align-right {
    text-align: right;
    margin-top: 20px;
    margin-bottom: 0;
}

main .content p.align-right select {
    width: 300px;
    max-width: 100%;
}

main .content .partenaire img {
    margin: 0 6px;
    max-height: 65px;
    max-width: 100%;
}



@media (min-width: 992px) {
    .navbar-nav {
        flex-direction: row;
    }

    .navbar {
        display: flex !important;
    }

    .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1200px) {
    .container {
        max-width: 1140px;
    }

    .references {
        grid-template-columns: repeat(auto-fill,minmax(50%, 1fr));
    }

    .form-reference #reference_themes, .form-reference #reference_natures {
        grid-template-columns: repeat(auto-fill,minmax(50%, 1fr));
    }

    main .content p.align-right select {
        margin-top: 45px;
    }

}


@media (max-width: 992px) {

    body {
        font-size: 26px;
    }

    .references {
        grid-template-columns: repeat(auto-fill,minmax(100%, 1fr));
    }

    .navbar {
        width: 100%;
        margin-left:  initial;
    }

    main .content {
        padding: 0;
    }

    #quote.form-quote {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(100%, 1fr));
    }

    .block-logo {
        margin: 0 auto 20px;
    }

    main .content p.align-right select {
        margin-top: 45px;
    }

    /* menu mobile */
    .navbar-toggler {
        padding: 0.25rem 0.75rem;
        font-size: 1.25rem;
        line-height: 1;
        background-color: black;
        border: 1px solid black;
        transition: box-shadow .15s ease-in-out;
        color: black;
        border-radius: 50%;
        width: 4em;
        height: 4em;
        position: absolute;
        bottom: -2em;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        cursor: pointer;
    }

    .navbar-toggler-icon {
        color: white;
        display: inline-block;
        width: 2em;
        height: 2em;
        vertical-align: middle;
        content: "";
        background: no-repeat center center;
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        cursor: pointer;
    }

    .logo {
        font-size: 70px;
    }

    .slogan {
        font-size: 26px;
    }

    .for-2columns {
        grid-template-columns: repeat(auto-fill,minmax(100%, 1fr));
    }

    .btn {
        font-size:34px;
    }

    main .content .second-contact li p {
        text-align: left;
        margin: 0 0 10px 0;
    }

    .form-quote div, .form-login div {
        margin: 0 0 30px;
    }


    form-quote label, .form-login label {
        font-family: 'Cormorant Garamond', serif;
        font-weight: bold;
        font-size: 34px;
    }

    main .content .reference img {
        vertical-align: middle;
        width: 50%;
    }

    main .reference:hover div.legend .ref-hidden-cont {
        height: 100%;
        left: 25%;
        margin: 0 auto;
        width: 50%;
    }

    main .reference:hover div.legend .ref-hidden {
        font-size: 34px;
    }

    main .content .references p {
        text-align: center;
    }

    main .content .reference img {
        width: 100%;
        max-width: 50%;
        margin: 0;
    }

    footer a {
        color: #555555;
        font-size: 14px;
    }
}